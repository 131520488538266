<script>
    import * as Utils from '@/modules/utils';

    import {showSignupModal, hideLoginModal, loginUser} from '@/actions/userActions';
    import {userStore} from '@/stores/userStore';

    import {FormManager, required} from '@/modules/formValidator.svelte.js';

    import I18n from '@/modules/translations';
    import Notification from '@/components/layout/notification';
    import GoogleConnectionButton from '@/components/layout/connection/GoogleConnectionButton.svelte';

    import Input from '@/components/themes/Input.svelte';
    import Spinner from '@/components/themes/Spinner.svelte';

    import Icon from '@/components/themes/Icon.svelte';
    import accountIcon from '@/components/icons/accountIcon';
    import lockIcon from '@/components/icons/lockIcon';

    import AnalyticsService from '../../../assets/javascripts/modules/analyticsService';

    const formManager = new FormManager({
        login: {
            validators: [required]
        },
        password: {
            validators: [required]
        }
    });

    let isSubmitting = $state(false);
    const onShowSignup = (event) => {
        event.preventDefault();

        hideLoginModal();
        showSignupModal();
    };

    const onSubmit = (event) => {
        event.preventDefault();

        isSubmitting = true;

        loginUser(formManager.values())
            .then((response) => {
                isSubmitting = false;

                if (response?.errors || response?.error) {
                    Notification.error(response.errors || response.error);
                } else if (userStore.noRedirection) {
                    AnalyticsService.trackLoginSuccess(response?.user?.id);

                    if (response.meta?.token) {
                        const csrfToken = document.getElementsByName('csrf-token')[0];
                        csrfToken?.setAttribute('content', response.meta.token);
                    }

                    userStore.isConnected = true;

                    hideLoginModal();
                } else {
                    const location = userStore.redirectionURL || response?.meta?.location || window.location;

                    if (response?.meta?.location) {
                        AnalyticsService.trackLoginSuccess(response?.user?.id);
                    }

                    Utils.forcePageReload(location);
                }
            });
    };

    const _handleGoogleConnection = (userId, userSlug, crsfToken) => {
        if(!userId) {
            Notification.error(I18n.t('js.user.signup.flash.google.not_connected'));

            return;
        }

        Notification.success(I18n.t('js.user.signup.flash.google.connected'));

        if (userStore.noRedirection) {
            if (crsfToken) {
                const csrfToken = document.getElementsByName('csrf-token')[0];
                csrfToken?.setAttribute('content', crsfToken);
            }

            AnalyticsService.trackLoginSuccess(userId);

            userStore.isConnected = true;

            hideLoginModal();
        } else {
            const location = userStore.redirectionURL || window.location;

            if (location) {
                AnalyticsService.trackLoginSuccess(userId);
            }

            Utils.forcePageReload(location);
        }
    };
</script>

<form class="flex flex-col space-y-6"
      action="/login"
      onsubmit={onSubmit}>
    <Input label={I18n.t('js.user.login.login')}
           type="text"
           name="login"
           required="true"
           autocomplete="username"
           oninput={(event) => formManager.onFormInputChange(event)}
           onblur={(event) => formManager.onFormInputBlur(event)}
           error={formManager.isError('login') ? 'red' : null}>
        {#snippet iconRender()}
            <Icon class="shrink-0 w-6 h-6"
                  icon={accountIcon}/>
        {/snippet}

        {#snippet textHelperRender()}
            {formManager.formErrorMessage('login', {required: I18n.t('js.user.errors.pseudo.required')})}
        {/snippet}
    </Input>

    <Input label={I18n.t('js.user.login.password')}
           type="password"
           name="password"
           required="true"
           autocomplete="current-password"
           oninput={(event) => formManager.onFormInputChange(event)}
           onblur={(event) => formManager.onFormInputBlur(event)}
           error={formManager.isError('password') ? 'red' : null}>
        {#snippet iconRender()}
            <Icon class="shrink-0 w-6 h-6"
                  icon={lockIcon}/>
        {/snippet}

        {#snippet textHelperRender()}
            {formManager.formErrorMessage('password', {required: I18n.t('js.user.errors.pseudo.required')})}
        {/snippet}
    </Input>

    <button type="submit"
            class="inline-flex items-center justify-center w-full px-5 py-2.5 text-sm text-white text-center font-medium focus-within:ring-4 focus-within:outline-none hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700 focus-within:ring-primary-300 dark:focus-within:ring-primary-800 rounded-lg cursor-pointer"
            class:bg-primary-700={!isSubmitting}
            class:bg-primary-500={isSubmitting}
            disabled={isSubmitting}>
        {#if isSubmitting}
            <Spinner class="me-3"
                     size="4"
                     color="white"/>
            {I18n.t('js.user.login.connecting')}
        {:else}
            {I18n.t('js.user.login.submit')}
        {/if}
    </button>
</form>

<div class="divider-content text-gray-500 text-xs italic">
    {I18n.t('js.helpers.or')}
</div>

<GoogleConnectionButton isLogin={true}
                        onConnected={_handleGoogleConnection}/>

<div class="mt-4 text-sm font-medium text-gray-500">
    {I18n.t('js.user.login.signup')}
    <a href="/signup"
       class="pl-1 font-medium text-primary-600 hover:underline"
       onclick={onShowSignup}>
        {I18n.t('js.user.login.signup_link')}
    </a>
    <span class="px-2">
        •
    </span>
    {I18n.t('js.user.login.password_forgotten')}
    <a href="/users/password/new"
       class="font-medium text-primary-600 hover:underline">
        {I18n.t('js.user.login.password_link')}
    </a>
</div>