'use strict';


let hasLocalStorage;
try {
    hasLocalStorage = !!window.localStorage;
} catch (error) {
    // AnalyticsService.trackStorageError();
}


const StorageService = (function () {
    const StorageServiceModel = {
        hasLocalStorage: hasLocalStorage,
        localDataPrefix: 'MAPPLANNER-',
        // Store data inside windows if localStorage not available
        windowLocalKey: 'MP-STORAGE'
    };

    if (!StorageServiceModel.hasLocalStorage) {
        window[StorageServiceModel.windowLocalKey] = {};
    }

    const _get = function (key) {
        if (StorageServiceModel.hasLocalStorage) {
            const itemStr = window.localStorage.getItem(key);
            // if the item doesn't exist, return null
            if (!itemStr || itemStr === 'undefined') {
                return null;
            }

            return JSON.parse(itemStr);
        } else {
            return window[StorageServiceModel.windowLocalKey][key];
        }
    };

    const _save = function (key, value) {
        if (!key) {
            return;
        }

        if (!value) {
            _remove(key);
            return;
        }

        if (StorageServiceModel.hasLocalStorage) {
            window.localStorage.setItem(key, JSON.stringify(value));
        } else {
            window[StorageServiceModel.windowLocalKey][key] = value;
        }
    };

    const _remove = function (key) {
        if (StorageServiceModel.hasLocalStorage) {
            window.localStorage.removeItem(key);
        } else if (window[StorageServiceModel.windowLocalKey]) {
            delete window[StorageServiceModel.windowLocalKey][key];
        }
    };

    StorageServiceModel.getItem = function (key) {
        return _get(key);
    };

    StorageServiceModel.setItem = function (key, value) {
        return _save(key, value);
    };

    StorageServiceModel.removeItem = function (key) {
        return _remove(key);
    };

    StorageServiceModel.saveLocalArray = function (dataName, dataParams, concat = true, compact = false) {
        const currentItem = `${StorageServiceModel.localDataPrefix}${dataName}`;
        const previousData = _get(currentItem);
        let currentData = [];

        if (previousData) {
            currentData = previousData;
            if (concat) {
                currentData = currentData.concat(dataParams);
            }
            if (compact) {
                currentData = currentData.concat(dataParams)
                    .compact()
                    .uniq();
            }
        } else {
            currentData = currentData.concat(dataParams);
        }

        _save(currentItem, currentData);
    };

    StorageServiceModel.getLocalData = function (dataName, remove = false) {
        const currentItem = `${StorageServiceModel.localDataPrefix}${dataName}`;
        const previousData = _get(currentItem);

        if (remove) {
            _remove(currentItem);
        }

        if (previousData) {
            return previousData;
        }
    };

    // StorageServiceModel.saveLocalData = function (dataName, dataParams) {
    //     if (hasLocalStorage) {
    //         const currentItem = `${localDataPrefix}${dataName}`;
    //
    //         window.localStorage.setItem(currentItem, JSON.stringify(dataParams));
    //     }
    // };

    // StorageServiceModel.removeLocalData = function (dataName) {
    //     if (hasLocalStorage) {
    //         const currentItem = `${localDataPrefix}${dataName}`;
    //         return _remove(currentItem);
    //     }
    // };

    // StorageServiceModel.getAllData = function () {
    //     const previousData = {};
    //
    //     if (hasLocalStorage) {
    //         Object.entries(window.localStorage).forEach(([, objKey]) => {
    //             if (objKey.startsWith(localDataPrefix)) {
    //                 const previousDataName = objKey.replace(localDataPrefix, '');
    //                 const previousDataParams = window.localStorage.getItem(objKey);
    //
    //                 if (previousDataParams) {
    //                     previousData[previousDataName] = JSON.parse(previousDataParams);
    //                 }
    //
    //                 _remove(objKey);
    //             }
    //         });
    //     }
    //
    //     return previousData;
    // };

    return StorageServiceModel;
})();

export default StorageService;
