/** VALIDATORS **/
export const isEmpty = (obj) => {
    // null and undefined are "empty"
    if (obj == null) return true;
    if (obj === 'undefined' || obj === 'null' || obj === 'false') return true;

    // Boolean are not empty
    if (obj === true || obj === false) return false;

    // Check if Set or Map objects
    if(obj instanceof Set) return obj.size === 0;
    if(obj instanceof Map) return obj.size === 0;

    // Check if is a Integer
    if (!Number.isNaN(parseFloat(obj)) && Number.isFinite(obj)) return false;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};

export const isPresent = (obj) => {
    return !isEmpty(obj);
};

// Create the following functions :
// isArray, isObject, isString, isDate, isRegExp, isFunction, isBoolean, isNull, isUndefined
// USage : Utils.is().isArray(myArray);
export const is = () => {
    const exports = {};

    const types = 'Array Object String Date RegExp Function Boolean Null Undefined'.split(' ');

    const type = function () {
        return Object.prototype.toString.call(this)
            .slice(8, -1);
    };

    for (let i = types.length; i--;) {
        exports['is' + types[i]] = ((self) => (obj) => type.call(obj) === self)(types[i]);
    }

    return exports;
};

/** STRING **/
String.prototype.capitalize = function () {
    return this.charAt(0)
        .toUpperCase() + this.slice(1);
};

String.prototype.replaceAt = function (startIndex, originalLength, replacement) {
    return this.substring(0, startIndex) + replacement + this.substring(startIndex + originalLength);
};

/** OBJECT **/
if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj);
        var i = ownProps.length;
        var resArray = new Array(i);
        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }

        return resArray;
    };
}

export const compact = (object) => {
    const newObject = {};
    Object.keys(object)
        .forEach((key) => {
            const value = object[key];
            if (isPresent(key) && isPresent(value)) {
                newObject[key] = value;
            }
        });
    return newObject;
};

export const sortObjectByKeys = (object, sortingArray) => {
    return Object.keys(object)
        .sort((a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b))
        .reduce(
            (obj, key) => {
                obj[key] = object[key];
                return obj;
            },
            {}
        );
};

/** ARRAY **/
Array.prototype.limit = function (limit) {
    if (this && !!limit) {
        return this.slice(0, limit);
    } else {
        return this;
    }
};

Array.prototype.remove = function (item) {
    if (this) {
        return this.filter((value) => value !== item);
    }
};

Array.prototype.first = function () {
    return this[0];
};

Array.prototype.last = function () {
    return this[this.length - 1];
};

Array.prototype.isEqualIds = function (other) {
    if (this.length !== other.length) {
        return false;
    }

    return this.every(function (element, index) {
        if (!other[index]) {
            return false;
        }

        return element.id === other[index].id;
    });
};

Array.prototype.uniqBy = function (uniqKey) {
    return [...new Map(this.map((item) => [item[uniqKey], item])).values()];
};

Array.prototype.replace = function (key, newValue) {
    const newArray = [];
    this.forEach(function (oldValue) {
        if (oldValue[key] === newValue[key]) {
            newArray.push(newValue);
        } else {
            newArray.push(oldValue);
        }
    });
    return newArray;
};

Array.prototype.replaceOrAdd = function (key, newValue) {
    const newArray = [];
    let valueNotFound = true;
    this.forEach(function (oldValue) {
        if (oldValue[key] === newValue[key]) {
            newArray.push(newValue);
            valueNotFound = false;
        } else {
            newArray.push(oldValue);
        }
    });
    if (valueNotFound) {
        newArray.push(newValue);
    }
    return newArray;
};

Array.prototype.uniq = function () {
    return this.filter((value, index, array) => array.indexOf(value) === index);
};

Array.prototype.compact = function () {
    return this.filter((val) => isPresent(val));
};

if (!Array.prototype.flat) {
    Object.defineProperty(Array.prototype, 'flat', {
        configurable: true,
        value: function flat() {
            const depth = Number.isNaN(arguments[0]) ? 1 : Number(arguments[0]);

            return depth ? Array.prototype.reduce.call(this, function (acc, cur) {
                if (Array.isArray(cur)) {
                    acc.push.apply(acc, flat.call(cur, depth - 1));
                } else {
                    acc.push(cur);
                }

                return acc;
            }, []) : Array.prototype.slice.call(this);
        },
        writable: true
    });
}

/** UTILS **/
// Use:
// decodeURIComponent(Utils.urlParam('distance_slider'))
// Utils.urlParam('distance_slider')
export const getUrlParameter = (sParam) => {
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');
    for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1];
        }
    }
};

export const parseUrlParams = (url = window.location.search.substring(1), transformIntoObject = false) => {
    let params = {};

    (new URLSearchParams(url)).forEach((name, value) => {
        let paramValue = decodeURIComponent(value);
        let paramName = decodeURIComponent(name);
        if (paramValue.endsWith('[]')) {
            paramValue = paramValue.replace('[]', ''), params[paramValue] || (params[paramValue] = []), params[paramValue].push(paramName);
        } else {
            let b = paramValue.match(/\[([a-z0-9_\/\s,.-])+]$/g);
            if (b) {
                paramValue = paramValue.replace(b, ''), b = b[0].replace('[', '')
                    .replace(']', ''), params[paramValue] || (params[paramValue] = []), params[paramValue][b] = paramName;
            } else {
                params[paramValue] = paramName;
            }
        }
    });

    if (transformIntoObject) {
        const transformParams = {};

        Object.entries(params)
            .forEach(([name, value]) => {
                const b = name.match(/^(\w+)\[(\w+)]$/);
                if (b && b.length === 3) {
                    transformParams[b[1]] ||= {};
                    transformParams[b[1]][b[2]] = value;
                } else {
                    transformParams[name] = value;
                }
            });

        params = transformParams;
    }

    return params;
};

export const flooredNum = (number, decimals) => {
    const multiplier = Math.pow(10, decimals);
    return Math.floor((number) * multiplier) / multiplier;
};

export const debounce = (func, wait, immediate, context) => {
    var result;
    var timerId = null;

    function cancel() {
        if (timerId !== undefined) {
            clearTimeout(timerId);
        }
    }

    function debounced() {
        var ctx = context || this;
        var args = arguments;
        var later = function () {
            timerId = null;
            if (!immediate) result = func.apply(ctx, args);
        };
        var callNow = immediate && !timerId;
        clearTimeout(timerId);
        timerId = setTimeout(later, wait);
        if (callNow) result = func.apply(ctx, args);
        return result;
    }

    debounced.cancel = cancel;

    return debounced;
};

export const throttle = (func, timeFrame) => {
    var lastTime = 0;

    return function (...args) {
        var now = new Date();
        if (now - lastTime >= timeFrame) {
            func(...args);
            lastTime = now;
        }
    };
};

export const getPreviousElement = (element, selector) => {
    if (!selector || !element) {
        return null;
    }

    if (selector) {
        let previous = element.previousElementSibling;
        while (previous && !previous.matches(selector)) {
            previous = previous.previousElementSibling;
        }
        return previous;
    } else {
        return element.previousElementSibling;
    }
};

export const getNextElement = (element) => {
    if (!element) {
        return null;
    }

    return element.nextElementSibling;
};

/** BROWSER **/
export const scrollTo = (elementId, margin = 40, behavior = 'smooth') => {
    const element = document.getElementById(elementId);
    if (!element) {
        return;
    }

    window.scroll({
        top: element.offsetTop - margin,
        behavior: behavior
    });
};

export const forcePageReload = (url = window.location) => {
    // Add timestamp to ensure page is not cached
    const timestamp = Date.now();
    const urlParams = window.location.search;
    const newUrl = url + (urlParams ? urlParams + '&' : '?') + `_=${timestamp}`;
    window.location.replace(newUrl);
};

export const clearForceReloadParam = () => {
    if (window.location.search.includes('_=')) {
        const url = new URL(window.location);
        url.searchParams.delete('_');
        window.history.replaceState(null, null, url);
    }
};

export const getCookie = (name) => {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
        return parts.pop()
            .split(';')
            .shift();
    } else {
        return null;
    }
};

export const addMultipleEventListener = (selector, events, eventHandler, removeEvent = false) => {
    if (!selector) {
        return null;
    }

    events = Array.isArray(events) ? events : events.split(' ');

    events.forEach(function (eventName) {
        if (removeEvent) {
            if (selector instanceof NodeList) {
                selector.forEach((s) => s.removeEventListener(eventName, eventHandler));
            } else {
                selector.removeEventListener(eventName, eventHandler);
            }
        }

        if (selector instanceof NodeList) {
            selector.forEach((s) => s.addEventListener(eventName, eventHandler));
        } else {
            selector.addEventListener(eventName, eventHandler);
        }
    });
};

export const onPageReady = (callback, timeout = undefined) => {
    if ('requestIdleCallback' in window) {
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            // call on next available tick
            if (timeout) {
                setTimeout(callback, timeout);
            } else {
                window.requestIdleCallback(callback);
            }
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                if (timeout) {
                    setTimeout(callback, timeout);
                } else {
                    window.requestIdleCallback(callback);
                }
            });
        }
    } else {
        let timeoutReference;

        document.addEventListener('beforeunload', () => {
            clearTimeout(timeoutReference);
        });

        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            // call on next available tick
            timeoutReference = setTimeout(callback, timeout);
        } else {
            document.addEventListener('DOMContentLoaded', () => timeoutReference = setTimeout(callback, timeout));
        }
    }
};
