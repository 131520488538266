import {writable} from 'svelte/store';

const initState = {
    basket: undefined,

    errors: undefined,

    product: undefined,
    productRequiredFunctionality: 'product_page',

    productModal: false
};

function BasketStore(state = initState) {
    const {
        subscribe,
        update
    } = writable(state);

    function updateState(data) {
        Object.entries(data)
            .forEach(([dataName, newData]) => {
                update((storeState) => {
                    storeState[dataName] = newData;

                    return storeState;
                });
            });
    }

    return {
        subscribe,
        get state() {
            return state;
        },
        set ridesData(basket) {
            updateState({basket});
        },
        set product(product) {
            updateState({product});
        },
        get product() {
            return state.product;
        },
        get basket() {
            return state.basket;
        },
        set basket(basket) {
            updateState({basket});
        },
        get errors() {
            return state.errors;
        },
        set errors(errors) {
            updateState({errors});
        },
        set productRequiredFunctionality(productRequiredFunctionality) {
            updateState({productRequiredFunctionality});
        },
        get productModal() {
            return state.productModal;
        },
        set productModal(productModal) {
            updateState({productModal});
        }
    };
}

export const basketStore = BasketStore();
