'use strict';

import RouteManager from '../modules/routeManager';

// Common routes
export const rootPath = (locale = window.locale) => RouteManager.routeBuilder('/', locale);

// Search routes
export const searchPath = (locale = window.locale) => RouteManager.routeBuilder(locale === false ? '/search/(.*)' : '/search/', locale);

// Rides routes
// export const ridesPath = (locale = window.locale) => RouteManager.routeBuilder('/rides', locale);
export const planRidesPath = (locale = window.locale) => RouteManager.routeBuilder('/rides/plan', locale);
export const explorePath = (locale = window.locale) => RouteManager.routeBuilder('/explore', locale);
// export const userRidesPath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/rides`, locale);
// export const userRidePath = (userSlug, rideSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/rides/${rideSlug}`, locale);
export const userRideTypePath = (userSlug, rideType, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/${rideType}`, locale);
export const userNewRidePath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/rides/new`, locale);
export const userEditRidePath = (userSlug, rideSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/rides/${rideSlug}/edit`, locale);
export const userPerformancesPath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/performances`, locale);
export const userNewPerformancePath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/performances/new`, locale);

// Places routes
export const placePath = (placeSlug, locale = window.locale) => RouteManager.routeBuilder(`/places/${placeSlug}`, locale);

// Tags routes
// export const tagsPath = (locale = window.locale) => RouteManager.routeBuilder('/tags', locale);
export const tagPath = (tagSlug, locale = window.locale) => RouteManager.routeBuilder(`/tags/${tagSlug}`, locale);

// Product routes
export const productPath = (productSlug, locale = window.locale) => RouteManager.routeBuilder(`/products/${productSlug}`, locale);

// Order routes
export const checkoutPath = (locale = window.locale) => RouteManager.routeBuilder('/checkout', locale);

// Blogs routes
export const blogsPath = (locale = window.locale) => RouteManager.routeBuilder('/blogs', locale);
export const blogPath = (articleSlug, locale = window.locale) => RouteManager.routeBuilder(`/blogs/${articleSlug}`, locale);

// Users routes
export const userPath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}`, locale);
export const userProfilePath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/profile`, locale);
export const userEditPath = (userSlug, locale = window.locale) => RouteManager.routeBuilder(`/users/${userSlug}/edit`, locale);
// export const newPasswordPath = (locale = window.locale) => RouteManager.routeBuilder('/users/password/new', locale);
// export const editPasswordPath = (locale = window.locale) => RouteManager.routeBuilder('/users/password/edit', locale);
// export const userConfirmationPath = (locale = window.locale) => RouteManager.routeBuilder('/users/confirmation', locale);

// Static routes
// export const contactPath = (locale = window.locale) => RouteManager.routeBuilder('/contact', locale);
// export const aboutPath = (locale = window.locale) => RouteManager.routeBuilder('/about', locale);
export const termsPath = (locale = window.locale) => RouteManager.routeBuilder('/terms', locale);
// export const privacyPath = (locale = window.locale) => RouteManager.routeBuilder('/privacy', locale);
// export const saleTermsPath = (locale = window.locale) => RouteManager.routeBuilder('/sales-terms', locale);
