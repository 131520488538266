'use strict';

// UI
export const notificationDuration = 6000;

// Requests
export const fetchTimeout = 6000;

// Rides
export const RideTypes = [
    'running',
    'hiking',
    'bike',
    'walk'
];

export const RideLevels = [
    'level_undefined',
    'very_easy',
    'easy',
    'medium',
    'difficult',
    'very_difficult'
];

export const RideVisibilities = [
    'everyone',
    'only_me'
];

export const RouteComputationTypes = [
    'foot',
    'hike',
    // 'hike_fastest',
    'bike',
    'mtb',
    'car',
    // 'car_fastest'
];

// Pois
export const PlanDateTypes = [
    'unplanned',
    'week',
    'month',
    'year',
    'date',
    'completed'
];

// export const rideProcessingTimer = 1000;

export const rideCountLimit = 500;

// Map
export const MapLayers = {
    MAP_PLANNER: 'map_planner',
    MAP_PLANNER_OUTDOOR: 'map_planner_outdoor',
    MAPBOX_OUTDOOR: 'mapbox_outdoor',
    MAPBOX_STREET: 'mapbox_street',
    MAPBOX_SATELLITE: 'mapbox_satellite',
    OPEN_STREET_MAP: 'open_street_map',
    IGN: 'ign'
};

export const MapLayerNames = {
    map_planner: 'MAP PLANNER',
    map_planner_outdoor: 'MAP PLANNER OUTDOOR',
    mapbox_outdoor: 'MAPBOX',
    mapbox_street: 'MAPBOX STREET',
    mapbox_satellite: 'MAPBOX SATELLITE',
    open_street_map: 'OSM',
    ign: 'IGN'
};

// Products
export const Products = {
    EXPLORER: 'explorer',
    EXPLORER_DISCOVER: 'explorer-discover'
};

export const ProductFunctionalities = {
    product_page: true,
    route_type: true,
    map_style: true,
    ride_max_count: true,
    import_max_count: true,
    ride_plan: true,
    explore_tracking: true,
    heart_data: true,
    max_distance: true,
    max_route_computation: true,
    map_max_tile_usage: true,
    pwa_mode: true,
    plan_poi_max_count: true
};

// Search
export const autocompleteLimit = 8;
export const maxSearchRate = 200;

// Autocomplete
export const suggestionsLimit = 20;

// Meta search
export const metaSearchLimit = 20;

// Uploads
export const maxWidthUpload = 2560;
export const maxHeightUpload = 2560;
