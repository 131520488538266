import {mount} from 'svelte';

import LoginForm from './Login.svelte';

const target = document.getElementById('login-form');

let loginForm;
if (target) {
    loginForm = mount(LoginForm, {target: target, props: {}});
}

export default loginForm;
