'use strict';

// Build routes from translated routes in locales/routes.*.yml

const RouteManager = (function () {
    function RouteManagerModel() {
        this._model = {
            locales: window.locales || ['en', 'fr', 'de', 'es', 'it'],
            localizedRoutes: {}
        };

        /* Private Methods
         ******************** */
        this._buildRoute = function (locale, path) {
            return path
                .split('/')
                .map((p) => this._model.localizedRoutes[locale][p] || p)
                .join('/')
                .replace(/^(.+?)\/*?$/, '$1') || '/';
        };

        this._buildRoutes = function (path) {
            return this._model.locales
                .map((l) => [this._model.localizedRoutes[l].locale].concat(path.substr(1)
                    .split('/')
                    .map((p) => (p.includes('|') ? '(' + p.substr(1)
                        .slice(0, -1)
                        .split('|')
                        .map((s) => this._model.localizedRoutes[l][s] || s)
                        .join('|') + ')' : this._model.localizedRoutes[l][p] || p)))
                    .join('/')
                    .replace(/^(.+?)\/*?$/, '$1'));
        };
    }

    const routeManager = RouteManagerModel.prototype;

    /* Public Methods
     ******************** */
    routeManager.initialize = function () {
        const applicationRoutes = document.getElementById('application-routes');
        if (applicationRoutes) {
            this._model.localizedRoutes = JSON.parse(applicationRoutes.innerText);
        }
    };

    routeManager.routeBuilder = function (path, locale) {
        if (locale) {
            locale ||= 'en';

            // if (!this._model.localizedRoutes[locale]) {
            //     console.error(`Localized routes are undefined for current locale (${locale}): ${JSON.stringify(this._model.localizedRoutes)}`);
            //     throw new Error(`Localized routes are undefined for current locale (${locale}): ${JSON.stringify(this._model.localizedRoutes)}`);
            // }

            if ((typeof GlobalEnvironment !== 'undefined' && GlobalEnvironment.NODE_ENV === 'production') || import.meta.env?.PROD) {
                return this._buildRoute(locale, path);
            } else {
                return (this._model.localizedRoutes[locale].locale || '') + this._buildRoute(locale, path);
            }
        } else {
            return this._buildRoutes(path);
        }
    };

    return RouteManagerModel;
})();

export default new RouteManager();
