import {validateUser} from '@/actions/userActions';

export class FormManager {
    _formState = {};
    _formValidators = {};

    form = $state({});

    constructor(initialState) {
        this._formState = this._buildFormState(initialState);

        this.form = this._setForm();
    }

    onFormInputChange(event) {
        this._formState[event.target.name].touched = true;

        this._formState[event.target.name].value = event.target.value;

        this._validateField(event.target.name, event.target.value);
    }

    onFormInputBlur(event) {
        this._formState[event.target.name].touched = true;

        this._validateField(event.target.name, event.target.value);
    }

    isError(fieldName) {
        return !this.form[fieldName].valid;
    }

    formErrorMessage(fieldName, validations) {
        if (!this.isError(fieldName)) {
            return null;
        }

        let errorMessage;

        Object.entries(validations)
            .forEach(([validatorName, validatorMessage]) => {
                if (errorMessage) {
                    return;
                }

                if (this._formState[fieldName].errors[validatorName]) {
                    errorMessage = validatorMessage;
                }
            });

        return errorMessage;
    }

    values() {
        const formValues = {};

        Object.keys(this._formState)
            .forEach((fieldName) => {
                formValues[fieldName] = this._formState[fieldName].value;
            });

        return formValues;
    }

    _validateField(fieldName, fieldValue) {
        this._formValidators[fieldName].forEach((validator) => {
            const fieldError = validator(fieldValue, this._formState);
            if (fieldError instanceof Promise) {
                fieldError.then((validatorResponse) => {
                    if (validatorResponse) {
                        this._formState[fieldName].errors[validator.name] = !!validatorResponse;
                    } else {
                        delete this._formState[fieldName].errors[validator.name];
                    }

                    this.form = this._setForm();
                });
            } else {
                if (fieldError) {
                    this._formState[fieldName].errors[validator.name] = !!fieldError;
                } else {
                    delete this._formState[fieldName].errors[validator.name];
                }

                this.form = this._setForm();
            }
        });
    }

    _setForm() {
        const newForm = {};

        Object.entries(this._formState)
            .forEach(([fieldName, fieldProperties]) => {
                newForm[fieldName] = {
                    valid: fieldProperties.touched ? !Object.keys(fieldProperties.errors).length : true
                };
            });

        return newForm;
    }

    _buildFormState(initialState) {
        const state = {};

        Object.entries(initialState)
            .forEach(([fieldName, fieldProperties]) => {
                state[fieldName] = {
                    touched: false,
                    errors: {},
                    value: undefined
                };

                this._formValidators[fieldName] = fieldProperties.validators;
            });

        return state;
    }
}

/**** Validators ****/
export function pseudoValidation(pseudo/*, formState */) {
    if (!pseudo) {
        return null;
    }

    return validateUser({pseudo: pseudo})
        .then((response) => {
            // // If there are already some validation errors, stop.
            // if (!formState.pseudo.valid) return;

            if (response.success === false) {
                return null;
            } else {
                return {pseudoValidation: 'alreadyTaken'};
            }
        });
}

export function required(value) {
    return value.trim() ? null : {required: 'Required'};
}

export function maxLength(length) {
    return function maxLength(value) {
        if (value.trim().length > length) {
            return {maxLength: length};
        }
    };
}

export function minLength(length) {
    return function minLength(value) {
        if (value.trim().length < length) {
            return {minLength: length};
        }
    };
}

export function specialCharacters(value) {
    const forbiddenCharacters = ['@', '/', '<'];

    if (new RegExp(forbiddenCharacters.join('|')).test(value)) {
        return {specialCharacters: true};
    }
}

export const email = (value) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
        return null;
    }

    return {email: {valid: false}};
};

export function emailValidation(emailInput/*, formState*/) {
    if (!emailInput) {
        return null;
    }

    return validateUser({email: emailInput})
        .then((response) => {
            // // If there are already some validation errors, stop.
            // if (!form.email.valid) return;

            if (response.success === false) {
                return null;
            } else {
                return {emailValidation: 'alreadyTaken'};
            }
        });
}

export function passwordMatch(value, formState) {
    if (value !== formState.password.value) {
        return {passwordMatch: true};
    }

    return null;
}
