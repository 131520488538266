import api, {isBeaconSupported} from '@/actions/api';

function sendBeaconOrPromise(url, params) {
    if (isBeaconSupported()) {
        return new Promise((resolve) => {
            api.sendBeacon(url, params);

            resolve();
        });
    } else {
        return api
            .post(url, params);
    }
}


export const updateTrackingConfiguration = (properties) => (
    sendBeaconOrPromise('/api/v1/tracking/configuration', properties)
);

export const trackLimitReached = (requiredFunctionality) => (
    sendBeaconOrPromise('/api/v1/tracking/limit-reached', {
        functionality: requiredFunctionality
    })
);

export const trackPaymentAttempt = (paymentTool) => (
    sendBeaconOrPromise('/api/v1/tracking/payment-attempt', {
        paymentTool
    })
);

export const trackPaymentError = (paymentError) => (
    sendBeaconOrPromise('/api/v1/tracking/payment-error', {
        paymentError
    })
);
