import {Drawer} from 'flowbite';

export const manageDrawer = function (drawerId, drawerButtonId, drawerCloseId, options = {}) {
    const $targetElement = document.getElementById(drawerId);
    const $drawerButtonElement = document.getElementById(drawerButtonId);
    const $drawerCloseElement = document.getElementById(drawerCloseId);
    if (!$targetElement || !$drawerButtonElement) {
        return;
    }

    const drawerOptions = {
        ...options,
        placement: 'left',
        backdrop: true,
        bodyScrolling: false,
        edge: false,
        edgeOffset: '',
        backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-drawer-background',
        // onHide: () => {
        // },
        // onShow: () => {
        // },
        // onToggle: () => {
        // }
    };

    const drawerElement = new Drawer($targetElement, drawerOptions);

    $drawerButtonElement.addEventListener('click', () => {
        drawerElement.show();
    });
    if ($drawerCloseElement) {
        $drawerCloseElement.addEventListener('click', () => {
            drawerElement.hide();
        });
    }

    return drawerElement;
};
