'use strict';

import AnalyticsService from './analyticsService';


const PWAManager = (function () {
    function PWAManagerModel() {
        this._model = {
            initialized: false,
            // Install prompt
            deferredInstallPrompt: undefined,
            serviceWorkerRegistration: undefined
        };

        /* Private Methods
         ******************** */
        this._isSubscribedUser = function () {
            // Check if user can install PWA
            return ['explorer', 'superuser'].includes(window.currentUserRole);
        };

        this._watchInstallPrompt = function (event) {
            this._model.deferredInstallPrompt = event;

            return event;
        };

        this._logAppInstalled = function (event) {
            // MapPlanner App was installed

            AnalyticsService.trackPWAInstalled();

            return event;
        };
    }

    const pwaManager = PWAManagerModel.prototype;

    pwaManager.MODE = {
        PWA: 'pwa',
        BROWSER: 'browser'
    };

    /* Public Methods
     ******************** */
    pwaManager.initialize = function () {
        if ((typeof GlobalEnvironment !== 'undefined' && GlobalEnvironment.NODE_ENV !== 'production') || import.meta.env?.DEV) {
            return;
        }

        if (!this._isSubscribedUser()) {
            return;
        }

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                // ServiceWorker is generated by Workbox webpack plugin
                navigator.serviceWorker?.register('/service-worker.js')
                    .then((registration) => {
                        this._model.initialized = true;

                        this._model.serviceWorkerRegistration = registration;

                        // Service worker registered: registration
                        if (this.getPWADisplayMode() !== pwaManager.MODE.BROWSER) {
                            AnalyticsService.trackPWAMode();
                        }
                    })
                    .catch(() => {
                        // Service worker registration failed: registrationError
                    });
            });

            window.addEventListener('beforeinstallprompt', this._watchInstallPrompt.bind(this));

            window.addEventListener('appinstalled', this._logAppInstalled.bind(this));
        }
    };

    // pwaManager.installPWAPrompt = function () {
    //     if (!this._model.initialized || !this._model.deferredInstallPrompt) {
    //         return;
    //     }
    //
    //     if (typeof this._model.deferredInstallPrompt.prompt !== 'function') {
    //         return;
    //     }
    //
    //     this._model.deferredInstallPrompt.prompt();
    //
    //     this._model.deferredInstallPrompt.userChoice
    //         .then((choice) => {
    //             if (choice.outcome === 'accepted') {
    //                 console.info('User accepted the A2HS prompt', choice);
    //             } else {
    //                 console.info('User dismissed the A2HS prompt', choice);
    //             }
    //             this._model.deferredInstallPrompt = null;
    //         });
    //
    //     AnalyticsService.trackPWAPrompt();
    // };

    pwaManager.getPWADisplayMode = function () {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        if (document.referrer.startsWith('android-app://')) {
            // twa
            return pwaManager.MODE.PWA;
        } else if (navigator.standalone || isStandalone) {
            // pwa
            return pwaManager.MODE.PWA;
        }

        return pwaManager.MODE.BROWSER;
    };

    pwaManager.clearServiceWorkerCaches = function (callback) {
        if (!this._model.initialized) {
            return;
        }

        if ('serviceWorker' in navigator) {
            caches.keys()
                .then((cacheNames) => {
                    cacheNames.forEach((cacheName) => {
                        caches.delete(cacheName);
                    });

                    this._model.serviceWorkerRegistration.update();

                    if (callback) {
                        callback();
                    }
                });
        } else if (callback) {
            callback();
        }
    };

    return PWAManagerModel;
})();

export default new PWAManager();
