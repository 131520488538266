import {initAccordions, initCollapses, initDropdowns, initModals, initDrawers, initTabs} from 'flowbite';

export default function flowbiteInitializer() {
    initAccordions();
    initCollapses();
    initDropdowns();
    initModals();
    initDrawers();
    initTabs();
}
