const PX_RATE = 4;

export const dot = function (color, pulse = false) {
    if (pulse) {
        const dotContainerElement = document.createElement('span');
        dotContainerElement.className = 'flex h-3 w-3';

        const dotPulseElement = document.createElement('span');
        dotPulseElement.className = 'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75';
        dotPulseElement.classList.add(color);

        const dotElement = document.createElement('span');
        dotElement.className = 'inline-flex rounded-full h-3 w-3';
        dotElement.classList.add(color);

        dotContainerElement.appendChild(dotPulseElement);
        dotContainerElement.appendChild(dotElement);

        return dotContainerElement;
    } else {
        const dotElement = document.createElement('span');
        dotElement.className = 'block rounded-full w-3 h-3 me-2';

        dotElement.classList.add(color);

        return dotElement;
    }
};

export const badge = function (value = '', design = {}) {
    const size = design.size || 5;
    const color = design.color || 'text-white';
    const bgColor = design.bgColor || 'bg-red-500';

    const badgeElement = document.createElement('span');

    badgeElement.className = `absolute inline-flex items-center justify-center text-xs font-bold ${color} ${bgColor} border-2 border-white rounded-full dark:border-gray-900`;

    badgeElement.style.width = `${PX_RATE * size}px`;
    badgeElement.style.height = `${PX_RATE * size}px`;
    badgeElement.style.top = `-${size * 1.6}px`;
    badgeElement.style.right = `-${size * 1.6}px`;

    badgeElement.innerHTML = value;

    return badgeElement;
};
