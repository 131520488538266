<script>
    import I18n from '@/modules/translations';

    const {
        isLogin = false,
        onConnected
    } = $props();

    const _handleButtonClick = (event) => {
        event.preventDefault();

        const popupWidth = 600;
        const popupHeight = 600;
        const left = (screen.width - popupWidth) / 2;
        const top = (screen.height - popupHeight) / 4;
        const popupParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${popupWidth},height=${popupHeight},top=${top},left=${left}`;

        var loginWindow = window.open('/oauth', 'OAuth Google', popupParams);
        var timer = setInterval(function () {
            if (loginWindow.closed) {
                clearInterval(timer);

                let connectedUserId;
                let connectedUserSlug;
                let newCsrfToken;
                try {
                    connectedUserId = loginWindow.currentUserId;
                    connectedUserSlug = loginWindow.currentUserSlug;
                    newCsrfToken = loginWindow.csrfToken;
                } catch (_) {
                    // Do nothing
                }

                onConnected(connectedUserId, connectedUserSlug, newCsrfToken);
            }
        }, 1000);
    };
</script>

<div class="connection-google">
    <button type="button"
            class="w-full text-sm text-center font-medium rounded-lg connection-google-button"
            onclick={_handleButtonClick}>
        {#if isLogin}
            {I18n.t('js.user.login.externals.google')}
        {:else}
            {I18n.t('js.user.signup.externals.google')}
        {/if}
    </button>
</div>

<style>
    .connection-google {
        .connection-google-button {
            display: inline-block;
            min-height: 34px;
            padding: 9px 10px 9px 40px;
            color: #fff;
            text-decoration: none;
            text-shadow: none;
            white-space: nowrap;
            border-color: #3b5998;
            background: #4184f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAaVJREFUOBG9lM8rRFEUx%2BchkQVp8iv5A7CwtsGCkiQWLJT8ASxkYSGxsrHyY2tHWZCysZYNoVjPRk2omaL8nIVcn%2FOcV3feu%2FPmrZz6OOee8%2F3ed8fMu6nUf4YxxoNp2IUr%2BIBv%2BIFXOIL2RGdCWAfHcANtYiIPg2wkG44m2igQYVgBib6gJ5n1kt815p3cas9ia8QPaqy1hfRq4FlnE%2FZM6opwQ9aIPVKzzuo1%2B8nzvAJFRnsd9kxq54aYDLN7ERAzf6nob1pXl0XduAWnXNWP9UL2vxTRUw9o%2FzDOH5lhqoITNT%2BS12Ad5AGSqyOmJA2MvTAHOyCxl8RXVsNGk%2F52xnyRZ8saSgkwd4O8LfMgb0kQmxSVpXyRPuImOFV3nnwG1%2FCpPUnLEaOrgTANGcjBuK1h3QDbIFGARnvurBFtiZoYdApoMjv3Fcb02xrnDxvBmIoubHGozuo6H%2BpHlzw5q09fjE790%2FUwfwO5ieQ1jQ9ECxDEPsUIdMEQbIDci0%2FQGb%2BTNUU8BXcQDvnpHIDzYi17XIxy58lJWkD%2BX7dcHjmyM34BtJe3X9SmBnsAAAAASUVORK5CYII%3D) no-repeat 10px center;
            transition: border-color .1s linear, background .1s linear, color .1s linear;

            &:hover {
                text-decoration: none;
                border-color: #346ac2;
                background-color: #346ac2;
                outline: 0;
            }
        }
    }
</style>