import {Modal} from 'flowbite';

import {onPageReady} from '@/modules/utils';

import AnalyticsService from '../../assets/javascripts/modules/analyticsService';

export const manageModal = function (modalId, modalComponent, options = {}) {
    const $targetElement = document.getElementById(modalId);
    if (!$targetElement) {
        return;
    }

    const modalOptions = {
        placement: 'center',
        backdrop: 'dynamic',
        backdropClasses: 'modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-modal-background-connection',
        closable: true,
        onShow: () => {
            modalComponent();

            if (modalId === 'signup-modal') {
                AnalyticsService.trackSignupPage();
            } else if (modalId === 'login-modal') {
                AnalyticsService.trackLoginPage();
            }

            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.classList.remove('hidden');
            }
        }
    };

    const modalElement = new Modal($targetElement, modalOptions);

    const $modalButtons = document.querySelectorAll('[data-modal-target="' + modalId + '"]');
    if ($modalButtons?.length) {
        $modalButtons.forEach(function (modalButton) {
            modalButton.addEventListener('click', (event) => {
                event.preventDefault();

                modalElement.show();
            });
        });
    }

    const $close = $targetElement.querySelector('button[data-modal-hide]');
    if ($close) {
        $close.addEventListener('click', (event) => {
            event.preventDefault();

            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.classList.add('hidden');
            }
        });
    }

    if (options.preloadTime) {
        onPageReady(() => {
            modalComponent();
        }, options.preloadTime);
    }

    return modalElement;
};
