import {Dropdown} from 'flowbite';

import * as Utils from '@/modules/utils';

import I18n from '@/modules/translations';

import {userStore} from '@/stores/userStore';

import {initUser, updateUserUnit} from '@/actions/userActions';

import Notification from '@/components/layout/notification';


class UserManager {
    /* Public methods
    ******************** */
    initialize = () => {
        this._initUser();

        this._handleUnitChange();
    };

    /* Private methods
    ******************** */
    _initUser = () => {
        const userId = Utils.getCookie('user.connected_id') || window.currentUserId;

        if (userId) {
            initUser(userId);
        } else {
            userStore.featureFlags = window.features;
        }
    };

    _handleUnitChange = () => {
        const kmButtonElement = document.getElementById('unit-menu-km');
        const miButtonElement = document.getElementById('unit-menu-mi');
        if (!kmButtonElement || !miButtonElement) {
            return;
        }

        kmButtonElement.onclick = (event) => {
            event.preventDefault();

            this._changeUserUnit('km');
        };

        miButtonElement.onclick = (event) => {
            event.preventDefault();

            this._changeUserUnit('mi');
        };
    };

    _changeUserUnit = (newUnit) => {
        window.unit = newUnit;

        updateUserUnit(newUnit)
            .then(() => {
                const dropdown = new Dropdown(document.getElementById('language-dropdown-menu'), document.getElementById('language-dropdown-button'));
                if (dropdown) {
                    dropdown.hide();
                }

                const unitMenuElement = document.getElementById('unit-menu-display');
                if (unitMenuElement) {
                    unitMenuElement.textContent = newUnit;
                }

                userStore.unit = newUnit;

                if (window.location.pathname === '/' || window.location.pathname === '/explore') {
                    Notification.success(I18n.t('js.views.header.units.updated', {unit: newUnit}));
                } else {
                    Utils.forcePageReload(window.location);
                }
            });
    };
}

export default new UserManager();
