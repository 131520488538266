const OBJECT_TYPE = '[object Object]';
const ARRAY_TYPE = '[object Array]';
const SET_TYPE = '[object Set]';
const MAP_TYPE = '[object Map]';

('hasOwn' in Object) || (Object.hasOwn = Object.call.bind(Object.hasOwnProperty));

/**
 * Join path keys using query string `a[b]` style syntax.
 */
function join(path, key) {
    return path !== undefined && path !== null ? path + '[' + key + ']' : key;
}

/**
 * @description
 * Creates a querystring style object from a nested one.
 *
 * @example
 * var result = flatten({ a: { b: 1 }, c: { d: 1 } });
 * result; //-> { "a[b]": 1, "c[d]": 2 }
 *
 * @param object The object to flatten.
 */
export function flatten(object, path, result) {
    const type = Object.prototype.toString.call(object);

    if (result === undefined) {
        if (type === OBJECT_TYPE) {
            result = {};
        } else if (type === ARRAY_TYPE) {
            result = [];
        } else {
            return;
        }
    }

    for (const key in object) {
        /* istanbul ignore if */
        if (!Object.hasOwn(object, key)) {
            continue;
        }

        const value = object[key];
        if (value === undefined || value === null) {
            continue;
        }

        switch (Object.prototype.toString.call(value)) {
            case ARRAY_TYPE:
            case OBJECT_TYPE:
                flatten(value, join(path, key), result);
                break;
            case SET_TYPE:
            case MAP_TYPE:
                flatten([...value], join(path, key), result);
                break;
            default:
                result[join(path, key)] = value;
                break;
        }
    }

    return result;
}

export function paramsToURL(params) {
    // For GET requests, array of array are not managed by Rails, only flatten arrays

    const flattenParams = flatten(params);

    return new URLSearchParams(flattenParams).toString()
        // remove indexes inside array
        .replace(/%5B\d+%5D/g, '%5B%5D')
        // transform array of array into simple array
        .replace(/(?<bracket>%5B%5D){2,}/g, '%5B%5D');
}
