import {writable} from 'svelte/store';

// Stores are designed to manage state on the client. On the server, each request is handled independently. This means that if you use a Svelte store on the server, the state you set could be shared across multiple requests.

function isNumeric(str) {
    if (typeof str !== 'string') {
        return false;
    }
    return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

const formatFeatureFlags = (featureFlags) => {
    const featureFlagsFormatted = {};

    Object.entries(featureFlags || {})
        .forEach(([featureName, featureValue]) => {
            if (featureValue === true || featureValue === 'true') {
                featureFlagsFormatted[featureName] = true;
            } else if (featureValue === false || featureValue === 'false') {
                featureFlagsFormatted[featureName] = false;
            } else if (isNumeric(featureValue)) {
                featureFlagsFormatted[featureName] = parseFloat(featureValue);
            } else {
                featureFlagsFormatted[featureName] = featureValue;
            }
        });

    return featureFlagsFormatted;
};

const initState = {
    isConnected: !!window.currentUserId,

    locale: window.locale,
    unit: window.unit,
    currency: window.currency,

    user: null,

    featureFlags: {},

    signupModal: null,
    loginModal: null,
    noRedirection: false,
    redirectionURL: null,

    // currentId: window.currentUserId ? parseInt(window.currentUserId, 10) : undefined,
    // currentSlug: window.currentUserSlug,
    // currentRole: window.currentUserRole,
    // isSubscribedUser: ['explorer', 'superuser'].includes(window.currentUserRole),
    // userConnectionToken: undefined,

    // isAdminConnected: !!window.currentAdminId,
};

// Custom Svelte stores allow you to encapsulate related logic within a store, and expose a clear and specific API.
export function UserStore(state = initState) {
    // writable has three methods : subscribe, set, and update
    // set takes one argument which is the value to be set, and update takes one argument which is a callback
    const {
        subscribe,
        // set,
        update
    } = writable(
        state
    );

    function updateState(data) {
        Object.entries(data)
            .forEach(([dataName, newData]) => {
                update((storeState) => {
                    storeState[dataName] = newData;

                    return storeState;
                });
            });
    }

    return {
        subscribe,
        get state() {
            return state;
        },
        get isConnected() {
            return state.isConnected;
        },
        set isConnected(isConnected) {
            updateState({isConnected});
        },
        get unit() {
            return state.unit;
        },
        set unit(unit) {
            updateState({unit});
        },
        set user(user) {
            updateState({user});
        },
        get featureFlags() {
            return state.featureFlags;
        },
        set featureFlags(featureFlags) {
            updateState({featureFlags: formatFeatureFlags(featureFlags)});
        },
        set signupModal(signupModal) {
            updateState({signupModal});
        },
        set loginModal(loginModal) {
            updateState({loginModal});
        },
        get noRedirection() {
            return state.noRedirection;
        },
        set noRedirection(noRedirection) {
            updateState({noRedirection});
        },
        get redirectionURL() {
            return state.redirectionURL;
        },
        set redirectionURL(redirectionURL) {
            updateState({redirectionURL});
        }
    };
}

export const userStore = UserStore();
