var DOMNodeCollection = (function () {
    function DOMNodeCollectionModel(nodeElements) {
        this.nodeElements = nodeElements;
        this.length = nodeElements.length;
    }

    var nodeCollection = DOMNodeCollectionModel.prototype;

    nodeCollection.show = function (type) {
        this.nodeElements.forEach(function (nodeElement) {
            nodeElement.style.display = type || 'block';
        });

        return this;
    };

    nodeCollection.hide = function () {
        this.nodeElements.forEach(function (nodeElement) {
            nodeElement.style.display = 'none';
        });

        return this;
    };

    nodeCollection.html = function (arg) {
        if (arg === undefined) {
            return this.nodeElements[0] && this.nodeElements[0].innerHTML;
        } else {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.innerHTML = arg;
            });
        }
        return this;
    };

    nodeCollection.empty = function () {
        this.html('');

        return this;
    };

    nodeCollection.append = function (arg) {
        if (arg instanceof DOMNodeCollection) {
            arg.nodeElements.forEach(function (nodeElement) {
                this.nodeElements.forEach(function (ownEl) {
                    ownEl.innerHTML += nodeElement.outerHTML;
                });
            }.bind(this));
        } else if (arg instanceof HTMLElement) {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.innerHTML += arg.outerHTML;
            });
        } else {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.innerHTML += arg;
            });
        }
        return this;
    };

    nodeCollection.attr = function (arg, value) {
        if (typeof arg === 'string') {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.setAttribute(arg, value);
            });
        } else {
            this.nodeElements.forEach(function (htmlElement) {
                Object.keys(arg)
                    .forEach(function (element) {
                        htmlElement.setAttribute(element, arg[element]);
                    });
            });
        }
        return this;
    };

    nodeCollection.removeAttr = function (arg) {
        if (typeof arg === 'string') {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.removeAttribute(arg);
            });
        } else if (Array.isArray(arg)) {
            this.nodeElements.forEach(function (nodeElement) {
                arg.forEach(function (element) {
                    nodeElement.removeAttribute(element);
                });
            });
        }
        return this;
    };

    nodeCollection.data = function (arg, value) {
        if (value) {
            return this.attr('data-' + arg, value);
        } else {
            return this.nodeElements[0] && this.nodeElements[0].getAttribute('data-' + arg);
        }
    };

    nodeCollection.includeClass = function (arg) {
        var hasClass = false;

        if (Array.isArray(arg)) {
            this.nodeElements.forEach(function (nodeElement) {
                arg.forEach(function (element) {
                    hasClass = hasClass || nodeElement.classList.contains(element);
                });
            });
        } else {
            this.nodeElements.forEach(function (nodeElement) {
                hasClass = hasClass || nodeElement.classList.contains(arg);
            });
        }

        return hasClass;
    };

    nodeCollection.addClass = function (arg) {
        if (Array.isArray(arg)) {
            this.nodeElements.forEach(function (nodeElement) {
                arg.forEach(function (element) {
                    nodeElement.classList.add(element);
                });
            });
        } else {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.classList.add(arg);
            });
        }
        return this;
    };

    nodeCollection.removeClass = function (arg) {
        if (Array.isArray(arg)) {
            this.nodeElements.forEach(function (nodeElement) {
                arg.forEach(function (element) {
                    nodeElement.classList.remove(element);
                });
            });
        } else {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.classList.remove(arg);
            });
        }
        return this;
    };

    nodeCollection.css = function (property, value) {
        var cssValue;

        if (typeof property === 'string') {
            this.nodeElements.forEach(function (nodeElement) {
                if (typeof value === 'undefined') {
                    cssValue = nodeElement.style[property];
                } else {
                    nodeElement.style[property] = value;
                }
            });
        } else if (typeof property === 'object') {
            this.nodeElements.forEach(function (nodeElement) {
                Object.keys(property)
                    .forEach(function (style) {
                        nodeElement.style[style] = property[style];
                    });
            });
        }

        if (typeof value === 'undefined') {
            return cssValue;
        } else {
            return this;
        }
    };

    nodeCollection.text = function (value) {
        if (value || value === '' || value === 0) {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.textContent = value;
            });
        } else {
            return this.nodeElements[0] && this.nodeElements[0].textContent;
        }

        return this;
    };

    nodeCollection.children = function () {
        var allChildren = [];
        this.nodeElements.forEach(function (nodeElement) {
            var childList = Array.from(nodeElement.children);
            allChildren = allChildren.concat(childList);
        });
        return new DOMNodeCollection(allChildren);
    };

    nodeCollection.parents = function () {
        var allParents = [];
        this.nodeElements.forEach(function (nodeElement) {
            allParents.push(nodeElement.parentNode);
        });
        return new DOMNodeCollection(allParents);
    };

    nodeCollection.find = function (selector) {
        var descendantNodes = [];
        this.nodeElements.forEach(function (nodeElement) {
            nodeElement.querySelectorAll(selector)
                .forEach(function (node) {
                    descendantNodes = descendantNodes.concat(node);
                });
        });
        return new DOMNodeCollection(descendantNodes);
    };

    nodeCollection.remove = function () {
        while (this.nodeElements.length > 0) {
            var currentNode = this.nodeElements.pop();
            currentNode.remove();
        }
    };

    nodeCollection.on = function (eventType, callback) {
        this.nodeElements.forEach(function (nodeElement) {
            nodeElement.addEventListener(eventType, callback);
            var eventName = 'event-' + eventType + '-' + callback.name;
            nodeElement[eventName] = callback;
        });
        return this;
    };

    nodeCollection.off = function (eventType, callback) {
        // if no callback is given, all of that event type are removed
        var validEvents;
        if (eventType === undefined && callback === undefined) {
            this.nodeElements.forEach(function (nodeElement) {
                validEvents = Object.keys(nodeElement)
                    .filter(function (k) {
                        return k.match('event-');
                    });
                validEvents.forEach(function (func) {
                    var pulledType = func.split('-')[1];
                    nodeElement.removeEventListener(pulledType, nodeElement[func]);
                });
            });
        } else if (callback === undefined) {
            this.nodeElements.forEach(function (nodeElement) {
                validEvents = Object.keys(nodeElement)
                    .filter(function (k) {
                        return k.match('event-' + eventType);
                    });
                validEvents.forEach(function (func) {
                    nodeElement.removeEventListener(eventType, nodeElement[func]);
                    delete nodeElement[func];
                });
            });
        } else {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.removeEventListener(eventType, callback);
                delete nodeElement[`event-${eventType}-${callback.name}`];
            });
        }
        return this;
    };

    nodeCollection.first = function () {
        return this.nodeElements[0];
    };

    nodeCollection.last = function () {
        return this.nodeElements[this.nodeElements.length - 1];
    };

    nodeCollection.value = function (value) {
        if (value) {
            this.nodeElements.forEach(function (nodeElement) {
                nodeElement.value = value;
            });
        } else {
            return this.nodeElements[0] && this.nodeElements[0].value;
        }
    };

    // nodeCollection.length = function () {
    //     return this.nodeElements.length;
    // };

    nodeCollection.each = function (callback) {
        this.nodeElements.forEach(function (ind, el) {
            callback(ind, el);
        });
        return new DOMNodeCollection(this.nodeElements);
    };
    nodeCollection.forEach = nodeCollection.each;

    return DOMNodeCollectionModel;
})();

export default function Selector(selector, noProxy = false) {
    let selectorObject = null;

    if (typeof selector === 'string') {
        if (selector.startsWith('#') && !selector.includes(' ')) {
            var selectionId = document.getElementById(selector.replace('#', ''));
            if (selectionId) {
                selectorObject = new DOMNodeCollection([selectionId]);
            } else {
                selectorObject = null;
            }
        } else {
            var selection = document.querySelectorAll(selector);
            var selectedArr = Array.from(selection);
            selectorObject = new DOMNodeCollection(selectedArr);
        }
    } else if (selector instanceof Array) {
        return selector
            .map((element) => Selector(element, true))
            .reduce((acc, selObj) => {
                acc.length += selObj.length;
                acc.nodeElements = acc.nodeElements.concat(selObj.nodeElements);
                return acc;
            }, {
                length: 0,
                nodeElements: []
            });
    } else if (selector instanceof Node || selector instanceof HTMLElement) {
        selectorObject = new DOMNodeCollection([selector]);
    }

    if (!selectorObject) {
        return selectorObject;
    }

    if (noProxy) {
        return selectorObject;
    }

    return new Proxy(selectorObject, {
        get: function (target, methodOrAttributeName) {
            // First try to call a selector function
            if (Object.keys(Object.getPrototypeOf(target))
                .indexOf(methodOrAttributeName) !== -1) {
                return target[methodOrAttributeName].bind(target);
            }

            // Otherwise delegate to node object
            return function () {
                const originalArguments = arguments;

                return target.nodeElements.map(function (nodeElement) {
                    return nodeElement[methodOrAttributeName](...originalArguments);
                });
            };
        }
    });
}
