<script>
    import {showLoginModal, hideSignupModal, signupUser} from '@/actions/userActions';
    import {userStore} from '@/stores/userStore';

    import * as Utils from '@/modules/utils';
    import I18n from '@/modules/translations';
    import Notification from '@/components/layout/notification';

    import GoogleConnectionButton from '@/components/layout/connection/GoogleConnectionButton.svelte';

    import {
        FormManager,
        required,
        minLength,
        maxLength,
        pseudoValidation,
        specialCharacters,
        email,
        emailValidation,
        passwordMatch
    } from '@/modules/formValidator.svelte';

    import Input from '@/components/themes/Input.svelte';
    import Spinner from '@/components/themes/Spinner.svelte';
    import Checkbox from '@/components/themes/Checkbox.svelte';

    import Icon from '@/components/themes/Icon.svelte';
    import accountIcon from '@/components/icons/accountIcon';
    import emailIcon from '@/components/icons/emailIcon';
    import lockIcon from '@/components/icons/lockIcon';

    import {termsPath} from '../../../assets/javascripts/constants/routesHelper';

    import AnalyticsService from '../../../assets/javascripts/modules/analyticsService';

    const formManager = new FormManager({
        pseudo: {
            validators: [required, minLength(window.settings.user_pseudo_min_length), maxLength(window.settings.user_pseudo_max_length), pseudoValidation, specialCharacters]
        },
        email: {
            validators: [required, email, emailValidation]
        },
        password: {
            validators: [required, minLength(window.settings.user_password_min_length), maxLength(window.settings.user_password_max_length)]
        },
        password_confirmation: {
            validators: [required, passwordMatch]
        }
    });

    let isSubmitting = $state(false);

    const onShowSignup = (event) => {
        event.preventDefault();

        hideSignupModal();
        showLoginModal();
    };

    const onSubmit = (event) => {
        event.preventDefault();

        isSubmitting = true;

        signupUser(formManager.values())
            .then((response) => {
                isSubmitting = false;

                if (response?.errors || response?.error) {
                    Notification.error(response.errors || response.error);
                } else if (userStore.noRedirection) {
                    AnalyticsService.trackSignupSuccess(response?.user?.id);

                    if (response.meta?.token) {
                        const csrfToken = document.getElementsByName('csrf-token')[0];
                        csrfToken?.setAttribute('content', response.meta.token);
                    }

                    userStore.isConnected = true;

                    hideSignupModal();
                } else {
                    const location = userStore.redirectionURL || response?.meta?.location || window.location;

                    if (response?.meta?.location) {
                        AnalyticsService.trackSignupSuccess(response?.user?.id);
                    }

                    Utils.forcePageReload(location);
                }
            });
    };

    const _handleGoogleConnection = (userId, userSlug, crsfToken) => {
        if(!userId) {
            Notification.error(I18n.t('js.user.signup.flash.google.not_connected'));

            return;
        }

        Notification.success(I18n.t('js.user.signup.flash.google.connected'));

        if (userStore.noRedirection) {
            if (crsfToken) {
                const csrfToken = document.getElementsByName('csrf-token')[0];
                csrfToken?.setAttribute('content', crsfToken);
            }

            AnalyticsService.trackSignupSuccess(userId);

            userStore.isConnected = true;

            hideSignupModal();
        } else {
            const location = userStore.redirectionURL || window.location;

            if (location) {
                AnalyticsService.trackSignupSuccess(userId);
            }

            Utils.forcePageReload(location);
        }
    };
</script>

<form class="flex flex-col space-y-3"
      method="post"
      accept-charset="UTF-8"
      action="/users"
      onsubmit={onSubmit}>
    <Input label={I18n.t('js.user.signup.pseudo')}
           type="text"
           name="pseudo"
           required="required"
           autocomplete="username"
           oninput={(event) => formManager.onFormInputChange(event)}
           onblur={(event) => formManager.onFormInputBlur(event)}
           error={formManager.isError('pseudo') ? 'red' : null}>
        {#snippet iconRender()}
            <Icon class="shrink-0 w-6 h-6"
                  icon={accountIcon}/>
        {/snippet}

        {#snippet textHelperRender()}
            {formManager.formErrorMessage('pseudo', {
                required: I18n.t('js.user.errors.pseudo.required'),
                pseudoValidation: I18n.t('js.user.errors.pseudo.already_taken'),
                minLength: I18n.t('js.user.errors.pseudo.size', {
                    min: window.settings.user_pseudo_min_length,
                    max: window.settings.user_pseudo_max_length
                }),
                maxLength: I18n.t('js.user.errors.pseudo.size', {
                    min: window.settings.user_pseudo_min_length,
                    max: window.settings.user_pseudo_max_length
                }),
                specialCharacters: I18n.t('js.user.errors.pseudo.special_characters')
            })}
        {/snippet}
    </Input>

    <Input label={I18n.t('js.user.signup.email')}
           type="email"
           name="email"
           required="true"
           autocomplete="email"
           oninput={(event) => formManager.onFormInputChange(event)}
           onblur={(event) => formManager.onFormInputBlur(event)}
           error={formManager.isError('email') ? 'red' : null}>
        {#snippet iconRender()}
            <Icon class="shrink-0 w-6 h-6"
                  icon={emailIcon}/>
        {/snippet}

        {#snippet textHelperRender()}
            {formManager.formErrorMessage('email', {
                required: I18n.t('js.user.errors.email.required'),
                email: I18n.t('js.user.errors.email.invalid'),
                emailValidation: I18n.t('js.user.errors.email.already_taken')
            })}
        {/snippet}
    </Input>

    <Input label={I18n.t('js.user.signup.password')}
           type="password"
           name="password"
           required="true"
           autocomplete="new-password"
           oninput={(event) => formManager.onFormInputChange(event)}
           onblur={(event) => formManager.onFormInputBlur(event)}
           error={formManager.isError('password') ? 'red' : null}>
        {#snippet iconRender()}
            <Icon class="shrink-0 w-6 h-6"
                  icon={lockIcon}/>
        {/snippet}

        {#snippet textHelperRender()}
            {formManager.formErrorMessage('password', {
                required: I18n.t('js.user.errors.password.required'),
                minLength: I18n.t('js.user.errors.password.size', {
                    min: window.settings.user_password_min_length,
                    max: window.settings.user_password_max_length
                })
            })}
        {/snippet}
    </Input>

    <Input label={I18n.t('js.user.signup.password_confirmation')}
           type="password"
           name="password_confirmation"
           required="true"
           autocomplete="new-password"
           oninput={(event) => formManager.onFormInputChange(event)}
           onblur={(event) => formManager.onFormInputBlur(event)}
           error={formManager.isError('password_confirmation') ? 'red' : null}>
        {#snippet iconRender()}
            <Icon class="shrink-0 w-6 h-6"
                  icon={lockIcon}/>
        {/snippet}

        {#snippet textHelperRender()}
            {formManager.formErrorMessage('password_confirmation', {
                required: I18n.t('js.user.errors.password.required'),
                passwordMatch: I18n.t('js.user.errors.password.mismatch')
            })}
        {/snippet}
    </Input>

    <div class="flex items-start">
        <Checkbox name="terms"
                  required="true">
            <span class="terms">
                {I18n.t('js.user.signup.terms_of_use', {website: window.settings.website_name}) + ' '}<a
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500" href={termsPath()}
                    target="_blank">{I18n.t('js.user.signup.terms_of_use_name')}</a>
            </span>
        </Checkbox>
    </div>

    <button type="submit"
            class="inline-flex items-center justify-center w-full px-5 py-2.5 text-sm text-white text-center font-medium focus-within:ring-4 focus-within:outline-none hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700 focus-within:ring-primary-300 dark:focus-within:ring-primary-800 rounded-lg cursor-pointer"
            class:bg-primary-700={!isSubmitting}
            class:bg-primary-500={isSubmitting}
            disabled={isSubmitting}>
        {#if isSubmitting}
            <Spinner class="me-3"
                     size="4"
                     color="white"/>
            {I18n.t('js.user.signup.connecting')}
        {:else}
            {I18n.t('js.user.signup.submit')}
        {/if}
    </button>
</form>

<div class="divider-content text-gray-500 text-xs italic">
    {I18n.t('js.helpers.or')}
</div>

<GoogleConnectionButton onConnected={_handleGoogleConnection}/>

<div class="mt-4 text-sm font-medium text-gray-500">
    {I18n.t('js.user.signup.login')}
    <a href="/signup"
       class="font-medium text-primary-600 hover:underline dark:text-primary-500"
       onclick={onShowSignup}>
        {I18n.t('js.user.signup.login_link')}
    </a>
    <span class="px-2">
        •
    </span>
    {I18n.t('js.user.signup.password_forgotten')}
    <a href="/users/password/new"
       class="font-medium text-primary-600 hover:underline dark:text-primary-500">
        {I18n.t('js.user.signup.password_link')}
    </a>
</div>
