import {manageModal} from '@/modules/modal';

import {userStore} from '@/stores/userStore';


export function initializeConnection() {
    const isUserConnected = !!window.currentUserId;

    userStore.isConnected = isUserConnected;

    if (isUserConnected) {
        return;
    }

    const SignupForm = () => import('@/components/layout/signup');
    const LoginForm = () => import('@/components/layout/login');

    const signupModal = manageModal('signup-modal', SignupForm, {
        preloadTime: 1500
    });
    const loginModal = manageModal('login-modal', LoginForm, {
        preloadTime: 1500
    });

    userStore.signupModal = signupModal;
    userStore.loginModal = loginModal;
}
