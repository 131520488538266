<script>
    import classnames from 'classnames';

    const {
        class: className,
        inputClass,
        label,
        type,
        name,
        placeholder,
        defaultValue,
        required,
        autocomplete,
        autoFocus = false,
        textHelper,
        error,
        onfocus,
        //slots
        iconRender,
        textHelperRender,
        ...inputProps
    } = $props();
</script>

<label class={classnames('text-sm rtl:text-right font-medium block text-gray-900 dark:text-gray-300 space-y-2', className)}
       class:text-red-700={error}>
    <span>{label}</span>

    <div class="relative w-full">
        <div class="flex absolute inset-y-0 items-center text-gray-500 dark:text-gray-400 start-0 ps-2.5 pointer-events-none">
            {#if iconRender}
                {@render iconRender()}
            {/if}
        </div>

        <input type={type}
               class={classnames('block w-full disabled:cursor-not-allowed disabled:opacity-50 rtl:text-right p-2 focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 border-gray-500 dark:border-gray-600 sm:text-xs rounded-lg', inputClass)}
               class:ps-9={!!iconRender}
               class:border-red-500={error}
               name={name}
               required={required}
               autofocus={autoFocus}
               autocomplete={autocomplete}
               placeholder={placeholder}
               value={defaultValue}
               onfocus={onfocus}
               {...inputProps}/>
    </div>

    {#if textHelperRender}
        <p class="text-xs font-normal mt-2">
            {@render textHelperRender()}
        </p>
    {:else}
        <p class="text-xs font-normal mt-2"
           class:text-red-700={error}>
            {textHelper}
        </p>
    {/if}
</label>
