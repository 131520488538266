<script>
    import classnames from 'classnames';

    const {
        class: className,
        labelClass,
        color = 'primary',
        // inline = false,
        spacing = 'me-2',
        disabled,
        children,
        ...otherProps
    } = $props();

    const colorClasses = {
        primary: 'text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600',
        secondary: 'text-secondary-600 focus:ring-secondary-500 dark:focus:ring-secondary-600',
        red: 'text-red-600 focus:ring-red-500 dark:focus:ring-red-600',
        green: 'text-green-600 focus:ring-green-500 dark:focus:ring-green-600',
        purple: 'text-purple-600 focus:ring-purple-500 dark:focus:ring-purple-600',
        teal: 'text-teal-600 focus:ring-teal-500 dark:focus:ring-teal-600',
        yellow: 'text-yellow-400 focus:ring-yellow-500 dark:focus:ring-yellow-600',
        orange: 'text-orange-500 focus:ring-orange-500 dark:focus:ring-orange-600',
        blue: 'text-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600'
    };

    const disabledClasses = {
        primary: 'text-primary-200',
        secondary: 'text-secondary-200',
        red: 'text-red-200',
        green: 'text-green-200',
        purple: 'text-purple-200',
        teal: 'text-teal-200',
        yellow: 'text-yellow-200',
        orange: 'text-orange-200',
        blue: 'text-blue-200'
    };

    const inputClass = (colored, rounded, tinted, extraClass) => classnames(
        'w-4 h-4 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 focus:ring-2',
        spacing,
        tinted ? 'dark:bg-gray-600 dark:border-gray-500' : 'dark:bg-gray-700 dark:border-gray-600',
        rounded && 'rounded',
        colorClasses[colored],
        extraClass
    );
</script>

<label class={classnames('text-sm rtl:text-right font-medium block', labelClass, disabled && 'text-gray-400 dark:text-gray-500')}>
    <input type="checkbox"
           class={classnames(spacing, inputClass(color, true, disabled, className), disabled && disabledClasses[color])}
           value="true"
           disabled={disabled}
           {...otherProps}/>

    {@render children()}
</label>
