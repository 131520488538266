import * as Utils from '@/modules/utils';
import {manageModal} from '@/modules/modal';
import $ from '@/modules/selector';

import {showSignupModal} from '@/actions/userActions';
import {addToBasket, fetchProduct, hideProductModal} from '@/actions/basketActions';

import {userStore} from '@/stores/userStore';
import {basketStore} from '@/stores/basketStore';

import {badge} from '@/components/themes/frontend';

import {checkoutPath} from '../../assets/javascripts/constants/routesHelper';
import {Products} from '../../assets/javascripts/components/modules/constants';

import AnalyticsService from '../../assets/javascripts/modules/analyticsService';


class BasketManager {
    _hasBasket = false;

    _productCount = 0;

    /* Public methods
    ******************** */
    initialize = () => {
        this._productCount = parseInt(Utils.getCookie('baskets_count') || 0);
        this._hasBasket = !!this._productCount;

        this._initializeProductModal();

        if (this._hasBasket) {
            this._addBadgeToHeaderButton();
        }

        this._manageCountdown();
    };

    fetchProduct = () => {
        fetchProduct(window.currentUserTrial ? Products.EXPLORER_DISCOVER : Products.EXPLORER)
            .then((response) => {
                if (!response?.product) {
                    return;
                }

                basketStore.product = response.product;

                AnalyticsService.trackProductView(basketStore.product.slug, basketStore.product.name, basketStore.product.currentPrice.isoAnnualPrice);
            });
    };

    addToBasket = (origin = 'product_page') => {
        addToBasket(window.currentUserTrial ? Products.EXPLORER_DISCOVER : Products.EXPLORER, origin)
            .then((response) => {
                if (response.errors) {
                    basketStore.errors = response.errors.join(', ');

                    return;
                } else {
                    basketStore.errors = undefined;
                }

                basketStore.basket = response.basket;

                const product = basketStore.basket.products?.first();

                AnalyticsService.trackAddToBasket(product.slug, product.name, basketStore.basket.totalAmount)
                    .then(() => {
                        if (!userStore.isConnected) {
                            userStore.redirectionURL = checkoutPath();

                            hideProductModal();

                            showSignupModal();
                        } else {
                            window.location = checkoutPath();
                        }
                    });
            });
    };

    /* Private methods
    ******************** */
    _initializeProductModal = () => {
        const ProductModal = () => import('@/components/basket/productModal');

        basketStore.productModal = manageModal('product-modal', ProductModal);
    };

    _addBadgeToHeaderButton = () => {
        const $basketButtonDesktop = $('#basket-button-desktop');

        if ($basketButtonDesktop) {
            $basketButtonDesktop.addClass('relative');

            const $badge = badge();

            $basketButtonDesktop.appendChild($badge);
        }

        const $basketButtonMobile = $('#basket-button-mobile');
        if ($basketButtonMobile) {
            $basketButtonMobile.addClass('relative');

            const $badge = badge();

            $basketButtonMobile.appendChild($badge);
        }
    };

    _formatCountdown = (value) => {
        // calculate time left
        const days = Math.floor(value / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((value % (1000 * 60)) / 1000);

        return [days, hours, minutes, seconds];
    };

    _manageCountdown = () => {
        const $countdowns = $('[id^=\'product-countdown-\']');
        if (!$countdowns.length) {
            return;
        }

        $countdowns.forEach((countdown) => {
            const $countdown = $(countdown);
            const endDate = $countdown.data('end-date');
            const targetDate = new Date(endDate).getTime();

            const $days = $countdown.find('.countdown-days');
            const $hours = $countdown.find('.countdown-hours');
            const $minutes = $countdown.find('.countdown-minutes');
            const $seconds = $countdown.find('.countdown-seconds');

            let countDownDate = targetDate - (new Date()).getTime();

            let [days, hours, minutes, seconds] = this._formatCountdown(countDownDate);
            $days.html(days);
            $hours.html(hours);
            $minutes.html(minutes);
            $seconds.html(seconds);

            setInterval(() => {
                countDownDate = targetDate - (new Date()).getTime();

                [days, hours, minutes, seconds] = this._formatCountdown(countDownDate);

                $days.html(days);
                $hours.html(hours);
                $minutes.html(minutes);
                $seconds.html(seconds);
            }, 1000);
        });
    };
}

export default new BasketManager();
